<template>
    <div class="search-view l-stack">
        <!-- Search -->
        <div class="l-padded u-bb">
            <SearchField v-model="search" />
        </div>

        <div v-if="username" class="l-padded u-bb">
            <strong>{{ $t('welcome', { username }) }}</strong>
        </div>

        <template v-if="search == ''">
            <ListHeader>
                <div slot="title">
                    {{ $t('shared.assets') }}
                </div>
            </ListHeader>

            <List>
                <router-link :to="{ name: 'allAssets' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <TripMultipleDestinationsIcon
                                width="24"
                                height="24"
                            />

                            <span>
                                {{
                                    $t('allItems', {
                                        items: $t('shared.assets'),
                                    })
                                }}
                            </span>
                        </div>

                        <span>{{ trackers.length }}</span>
                    </ListItem>
                </router-link>

                <template v-if="isAlptracker">
                    <template v-if="fenceMonitoringTypeIds.length">
                        <router-link
                            :to="{
                                name: 'allAssets',
                                query: { type: fenceMonitoringTypeIds },
                            }"
                        >
                            <ListItem>
                                <div class="l-inline l-gap-2 l-center-v">
                                    <FenceGuardIcon width="24" height="24" />

                                    <span>
                                        {{ $t('fenceMonitoring') }}
                                    </span>
                                </div>

                                <span>{{ fenceMonitoringCount }}</span>
                            </ListItem>
                        </router-link>
                    </template>

                    <router-link
                        :to="{
                            name: 'allAssets',
                            query: { recent: true },
                        }"
                    >
                        <ListItem>
                            <div class="l-inline l-gap-2 l-center-v">
                                <RecentIcon
                                    width="24"
                                    height="24"
                                    color="black"
                                />

                                <span>
                                    {{ $t('recentlyActive') }}
                                </span>
                            </div>

                            <span>{{ activeInLast6HoursAssets.length }}</span>
                        </ListItem>
                    </router-link>
                </template>

                <router-link
                    :to="{
                        name: 'allAssets',
                        query: { alert: true },
                    }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <CheckIcon
                                v-if="!alertAssets.length"
                                width="24"
                                height="24"
                                color="#41b883"
                            />
                            <WarningIcon v-else width="24" height="24" />

                            <span>
                                {{
                                    $t('hasAlert', {
                                        items: $t('shared.assets'),
                                    })
                                }}
                            </span>
                        </div>

                        <span>{{ alertAssets.length }}</span>
                    </ListItem>
                </router-link>

                <router-link v-if="showLeftZone" :to="{ name: 'lostAssets' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <CursorMoveTargetRightIcon width="24" height="24" />

                            <span>{{ $t('leftZone') }}</span>
                        </div>

                        <span>{{ lostAssets.length }}</span>
                    </ListItem>
                </router-link>

                <router-link :to="{ name: 'lowBatteryAssets' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <BatteryIcon width="24" height="24" :warn="true" />

                            <span>{{ $t('lowBattery') }}</span>
                        </div>

                        <span>{{ lowBatteryAssets.length }}</span>
                    </ListItem>
                </router-link>

                <router-link :to="{ name: 'assetTypes' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <HierarchyIcon width="24" height="24" />

                            <span>{{ $t('router.assetTypes') }}</span>
                        </div>

                        <span>{{ assetTypes.length }}</span>
                    </ListItem>
                </router-link>
            </List>

            <ListHeader>
                <div slot="title">{{ $t('location') }}</div>
                <div slot="accessory">
                    <router-link :to="{ name: 'createlocation' }">
                        <IconButton>
                            <AddIcon
                                width="24"
                                height="24"
                                :name="$t('router.createlocation')"
                            />
                        </IconButton>
                    </router-link>
                </div>
            </ListHeader>

            <List>
                <router-link
                    v-for="location in locationsSortedByName"
                    :key="'loc' + location.id"
                    :to="{ name: 'location', params: { id: location.id } }"
                >
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <PinLocationIcon width="24" height="24" />

                            <span>{{ location.name }}</span>
                        </div>

                        <span>{{ locationAssetsCounts[location.id] }}</span>
                    </ListItem>
                </router-link>
            </List>

            <div v-if="isAlploraUser" class="u-bb">
                <router-link :to="{ name: 'globalAnimalActivityChart' }">
                    <ListItem>
                        <div class="l-inline l-gap-2 l-center-v">
                            <ActivityIcon width="24" height="24" />

                            <span>{{ $t('showActivity') }}</span>
                        </div>

                        <p><i class="arrow right" /></p>
                    </ListItem>
                </router-link>
            </div>

            <transition name="slide-from-right">
                <div v-if="isChartViewActive" class="detached-panel">
                    <div
                        class="l-inline l-center-v l-spread l-padded u-bb detached-panel-header"
                    >
                        {{ detachedPanelTitle }}

                        <router-link to=".">
                            <IconButton @click="$emit('expand')">
                                <RemoveIcon
                                    width="24"
                                    height="24"
                                    color="black"
                                />
                            </IconButton>
                        </router-link>
                    </div>

                    <div class="detached-panel-body">
                        <router-view />
                    </div>
                </div>
            </transition>
        </template>

        <!-- Search results -->
        <template v-else>
            <List>
                <router-link
                    v-for="item in filteredItems"
                    :key="'router' + item.vueKey"
                    :to="item.path"
                >
                    <ListItem :key="item.vueKey" class="u-bb">
                        <div class="l-stack l-gap-1">
                            <div>
                                <strong>{{ item.name }}</strong>
                            </div>

                            <div>{{ $t(item.type) }}</div>
                        </div>
                    </ListItem>
                </router-link>
            </List>
        </template>
    </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex'

import ActivityIcon from '../icons/ActivityIcon'
import AddIcon from '../icons/AddIcon'
import BatteryIcon from '../icons/BatteryIcon'
import CheckIcon from '../icons/CheckIcon'
import CursorMoveTargetRightIcon from '../icons/CursorMoveTargetRightIcon'
import FenceGuardIcon from '../icons/FenceGuardIcon'
import HierarchyIcon from '../icons/HierarchyIcon'
import IconButton from '../IconButton'
import List from '../List'
import ListHeader from '../ListHeader'
import ListItem from '../ListItem'
import PinLocationIcon from '../icons/PinLocationIcon'
import RecentIcon from '../icons/RecentIcon'
import RemoveIcon from '../icons/RemoveIcon'
import SearchField from '../SearchField'
import TripMultipleDestinationsIcon from '../icons/TripMultipleDestinationsIcon'
import WarningIcon from '../icons/WarningIcon'

export default {
    name: 'SearchView',
    components: {
        ActivityIcon,
        AddIcon,
        BatteryIcon,
        CheckIcon,
        CursorMoveTargetRightIcon,
        FenceGuardIcon,
        HierarchyIcon,
        IconButton,
        List,
        ListHeader,
        ListItem,
        PinLocationIcon,
        RecentIcon,
        RemoveIcon,
        SearchField,
        TripMultipleDestinationsIcon,
        WarningIcon,
    },
    data() {
        return {
            search: '',
            locationAssetsCounts: {},
        }
    },
    computed: {
        ...mapState('auth', ['hasActivityAccess', 'userInfo']),
        ...mapState('tracker', ['assetTypes', 'trackers']),
        ...mapGetters('location', ['locationsSortedByName']),
        ...mapGetters('tracker', [
            'activeInLast6HoursAssets',
            'alertAssets',
            'lostAssets',
            'lowBatteryAssets',
        ]),
        detachedPanelTitle() {
            return this.$t(`router.${this.$route.name}`)
        },
        fenceMonitoringTypeIds() {
            return this.assetTypes
                .filter(
                    type =>
                        type.identifier === 'fence-guard' ||
                        type.identifier === 'fency-boy'
                )
                .map(type => type.id)
        },
        fenceMonitoringCount() {
            return this.trackers.filter(
                tracker =>
                    tracker.asset_details.asset_type_type === 'fence-guard' ||
                    tracker.asset_details.asset_type_type === 'fency-boy'
            ).length
        },
        isChartViewActive() {
            return this.$route.name === 'globalAnimalActivityChart'
        },
        filteredItems() {
            if (!this.search) {
                return []
            }

            // Insert fancy search algorithm here…
            const filteredAssets = this.trackers
                .filter(
                    asset =>
                        asset.asset_details.name
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        asset.deveui
                            .toLowerCase()
                            .includes(this.search.toLowerCase()) ||
                        asset.asset_details.identification
                            ?.toLowerCase()
                            .includes(this.search.toLowerCase())
                )
                .map(asset => ({
                    id: asset.id,
                    vueKey: 'str' + asset.id,
                    name: asset.asset_details.name,
                    type: 'Asset',
                    path: 'map/assets/' + asset.id,
                }))

            const filteredLocations = this.locationsSortedByName
                .filter(location =>
                    location.name
                        .toLowerCase()
                        .includes(this.search.toLowerCase())
                )
                .map(location => ({
                    id: location.id,
                    vueKey: 'sloc' + location.id,
                    name: location.name,
                    type: 'location',
                    path: 'map/location/' + location.id,
                }))

            return [...filteredAssets, ...filteredLocations]
        },
        isAlploraUser() {
            return this.userInfo.platform === 'alplora'
        },
        username() {
            const {
                first_name: firstName,
                last_name: lastName,
                username,
            } = this.userInfo

            return `${firstName} ${lastName}`.trim() || username || ''
        },
        showLeftZone() {
            return process.env.VUE_APP_ENVIRONMENT_NAME !== 'orkanet'
        },
        isAlptracker() {
            return process.env.VUE_APP_ENVIRONMENT_NAME === 'alptracker'
        },
    },
    beforeMount() {
        this.calculateLocationAssetsCounts()
    },
    methods: {
        calculateLocationAssetsCounts() {
            this.locationAssetsCounts = this.locationsSortedByName.reduce(
                (counts, location) => {
                    counts[location.id] = this.trackers.filter(
                        asset => asset.location === location.id
                    ).length
                    return counts
                },
                {}
            )
        },
    },
}
</script>

<i18n>
{
    "en": {
        "allItems": "All {items}",
        "fenceMonitoring": "Fence monitoring",
        "hasAlert": "{items} with alert",
        "leftZone": "Left zone",
        "location": "Location",
        "lowBattery": "Low battery",
        "recentlyActive": "Active in the last 6 hours",
        "showActivity": "Show activity",
        "tracker": "Tracker",
        "welcome": "Welcome, {username}!"
    },
    "de": {
        "allItems": "Alle {items}",
        "fenceMonitoring": "Zaunüberwachungen",
        "hasAlert": "{items} mit Alarm",
        "leftZone": "Zone verlassen",
        "location": "Standort",
        "lowBattery": "Niedriger Batteriestand",
        "recentlyActive": "Aktiv in den letzten 6 Stunden",
        "showActivity": "Aktivität anzeigen",
        "tracker": "Tracker",
        "welcome": "Willkommen, {username}!"
    },
    "fr": {
        "allItems": "Tous {items}",
        "fenceMonitoring": "Surveillance des clôtures",
        "hasAlert": "{items} avec alarme",
        "leftZone": "Quitter la zone",
        "location": "Emplacement",
        "lowBattery": "Batterie faible",
        "recentlyActive": "Actif au cours des 6 dernières heures",
        "showActivity": "Afficher l'activité",
        "tracker": "Tracker",
        "welcome": "Bienvenue, {username} !"
    },
    "it": {
        "allItems": "Tutti {items}",
        "fenceMonitoring": "Monitoraggio della recinzione",
        "hasAlert": "{items} con allarme",
        "leftZone": "Escono Zona",
        "location": "Locazione",
        "lowBattery": "Basso Batteria",
        "recentlyActive": "Attivo nelle ultime 6 ore",
        "showActivity": "Mostra attività",
        "tracker": "Tracker",
        "welcome": "Benvenuto, {username}!"
    }
}
</i18n>

<style lang="scss" scoped>
.search-view > * + * {
    border-bottom: $style-border;
}
</style>
